.card {
	width: 100%;
}
.typography {
	color: #000;
}
.actionRoot {
	padding: 8px 8px 8px 16px;
	justify-content: space-between;
}
.icons {
	margin-left: auto;
}
.expand {
	padding: 8px 8px;
	transform: rotate(0deg);
	color: #000;
	transition: all 0.2s;
}
.expandOpen {
	transform: rotate(180deg);
}
.paper {
	background-color: #fff;
	padding: 16;
}
.checkIcon {
	font-size: 20;
	padding-right: 4;
}
.button {
	padding: 0;
	text-transform: none;
}
